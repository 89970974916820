(function ($) {


    function new_filter_map($el) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
                {
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#F1F1F1"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "color": "#1A1A1A"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#e53329"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        };


        // create map	        	
        var map = new google.maps.Map($el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function () {

            add_filter_marker($(this), map);

        });

        var clusterStyles = [
            {
                textColor: 'white',
                url: localize.themeurl + '/assets/img/svg/pinek/cluster.svg',
                height: 30,
                width: 30
            },
        ];

        //MarkerClusterer
        var options = {
            styles: clusterStyles,
        };

        $.markerCluster = new MarkerClusterer(map, map.markers, options);

        // center map
        $.center_filter_map(map);

        //Close infowindows when clicking away
        google.maps.event.addListener(map, "click", function (event) {
            $.closeAllInfoWindow();
        });

        // return
        return map;

    }

    /*
     *  add_filter_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    function add_filter_marker($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: {
                url: localize.themeurl + '/assets/img/svg/marker/pin.svg',
                scaledSize: new google.maps.Size(22, 28)
            },
            category: $marker.data('category'),
        });

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window

            marker['infowindow'] = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {
                if (currentmarker != this) {
                    marker.setIcon({
                        url: localize.themeurl + '/assets/img/svg/marker/pin_zold.svg',
                        scaledSize: new google.maps.Size(33, 42)
                    });

                    $.closeAllInfoWindow();


                    currentinfowindow = this['infowindow'];
                    currentmarker = this;
                    currentinfowindow.open(map, this);
                    //map.setCenter(this.getPosition());
                    removeDefaultInfowindow();
                }
            });
        }

    }

    $.closeAllInfoWindow = function closeAllInfoWindow() {
        if (currentinfowindow) {
            currentinfowindow.close();
            currentinfowindow = null;
        }
        if (currentmarker) {
            currentmarker.setIcon({
                url: localize.themeurl + '/assets/img/svg/marker/pin_lila.svg',
                scaledSize: new google.maps.Size(22, 28)
            });
            currentmarker = null;
        }


    }

    $(document).on('click', '.js-close-infowindow', function (e) {
        e.preventDefault();
        $.closeAllInfoWindow();
    });

    /*
     *  center_filter_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    $.center_filter_map = function center_filter_map(map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(14);
        } else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    }


    //Removing default infoWindow styles
    function removeDefaultInfowindow() {
        $('.gm-style-iw').next().css({ 'height': '0px' }); //remove arrow bottom inforwindow
        $('.gm-style-iw').prev().html(''); //remove style default inforwindows
        $('.gm-style-iw').parent().css('pointer-events', 'none');
    }


    //Filtering markers
    $.filterMarkers = function filterMarkers(category) {

        for (var i = 0; i < $.filtermap.markers.length; i++) {
            var markertohide = $.filtermap.markers[i];

            if (markertohide.category.indexOf(category) >= 0 || category.length === 0) {
                markertohide.setVisible(true);
            }
            // Categories don't match
            else {
                markertohide.setVisible(false);
            }
        }

    }

    /*
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	5.0.0
     *
     *  @param	n/a
     *  @return	n/a
     */
    // global var
    $.filtermap = null;
    var currentinfowindow = null;
    var currentmarker = null;
    $.markerCluster;
    $(function () {

        $('.js-filter-map').each(function () {

            // create map
            $.filtermap = new_filter_map($(this));

        });

    });

})(jQuery);