/* dev/assets/js/a-video.js */
(function ($) {
    "use strict";

    function sortDaysByToday(ds) {
        var days = { vasarnap: 0, hetfo: 1, kedd: 2, szerda: 3, csutortok: 4, pentek: 5, szombat: 6 },
            today = new Date().getDay()

        return ds.sort(function (a, b) {
            return (days[a] < today ? days[a] + 7 : days[a])
                - (days[b] < today ? days[b] + 7 : days[b])
        })
    }



    $(function () {


        if ($('.js-mh-places-title').length) {
            $('.js-mh-places-title').matchHeight({
                byRow: true
            });
        }


        if ($('.js-mh-places-address').length) {
            $('.js-mh-places-address').matchHeight({
                byRow: false
            });
        }

        if ($('.js-mh-places-content').length) {
            $('.js-mh-places-content').matchHeight({
                byRow: false
            });
        }

        if ($('.js-opening').length) {

            $('.js-current-day-opener').on('click', function () {
                $('.js-other-days').slideToggle();
                $(this).toggleClass('active');
            });

            var date = new Date();
            var weekday = new Array(7);
            weekday[0] = "vasarnap";
            weekday[1] = "hetfo";
            weekday[2] = "kedd";
            weekday[3] = "szerda";
            weekday[4] = "csutortok";
            weekday[5] = "pentek";
            weekday[6] = "szombat";

            var current_day = weekday[date.getDay()];
            var tempdays = Array();
            $('.js-day').each(function () {
                tempdays.push($(this).attr('data-day'))
            })
            var sorted_days = sortDaysByToday(tempdays);
            $('.js-current-day').text($(`.js-day[data-day="${current_day}"]`).text());
            if ($('.js-current-day').text() == "") {
                $('.js-current-day').text('Nyitvatartás');
            }
            $(`.js-day[data-day="${current_day}"]`).hide();

            sorted_days.forEach(function (id) {
                $(`.js-day[data-day="${id}"]`).appendTo(".js-other-days")
            });
            $('.js-other-days').append(`<p>A nyitvatartást mindig ellenőrizd a hivatalos weboldalon!</p>`)
            $('.js-opening').animate({ opacity: 1 }, 300);


        }
    });
})(jQuery);
