(function ($) {
    "use strict";

    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menu'),
        'padding': 256,
        'tolerance': 70,
        'side': 'right'
    });

    function close(e) {
        e.preventDefault();
        slideout.close();
    }

    $(function () {



        if ($(".js-mh-product-title").length) {
            $(".js-mh-product-title").matchHeight({
                byRow: true
            });
        }



        if ($(".js-mh-price").length) {
            $(".js-mh-price").matchHeight({
                byRow: true
            });
        }


        if ($(".js-mh-price-old").length) {
            $(".js-mh-price-old").matchHeight({
                byRow: true
            });
        }


        if ($(".js-mh-card-news").length) {
            $(".js-mh-card-news").matchHeight({
                byRow: true
            });
        }



        $('.js-filter-toggle').on('click', function (e) {
            e.preventDefault();
            $('.js-filter-toggle-wrapper').slideToggle();
        })


        $('.js-sidebar').stickySidebar({
            topSpacing: $('header').outerHeight(),
            bottomSpacing: 0,
            containerSelector: '.sidebar__container',
            innerWrapperSelector: '.plus-sidebar-wrapper',
            resizeSensor: true,
            minWidth: 992,
        });



        if ($('.js-product-slider').length) {

            var productSliderThumbs = new Swiper(".js-product-slider-thumbs", {
                loop: false,
                spaceBetween: 20,
                slidesPerView: "auto",
                freeMode: false,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                centeredSlides: true,

            });

            var productSlider = new Swiper(".js-product-slider", {
                loop: false,
                spaceBetween: 11,
                effect: "fade",
                thumbs: {
                    swiper: productSliderThumbs,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
        }


        if ($('.js-popular-products').length) {
            var popularProducts = new Swiper(".js-popular-products", {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".js-popular-products-next",
                    prevEl: ".js-popular-products-prev",
                },
                breakpoints: {
                    520: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 4,
                    },
                },


            });

        }



        if ($('.js-goto-newsletter').length) {
            var elevator = new Elevator({
                element: document.querySelector('.js-goto-newsletter'),
                targetElement: document.querySelector('.js-newsletter'),

            });
        }


        $('.js-sticky-sidebar').stickySidebar({
            topSpacing: -parseInt($(".js-aside-inner").css("margin-top")) * 2,
            bottomSpacing: 0,
            containerSelector: '.sticky-wrapper',
            innerWrapperSelector: '.sticky-sidebar__inner',
            resizeSensor: true,
            minWidth: 991
        });

        $('.js-sticky-sidebar-places').stickySidebar({
            topSpacing: 0,
            bottomSpacing: 0,
            containerSelector: '.sticky-wrapper',
            innerWrapperSelector: '.sticky-sidebar__inner',
            resizeSensor: true,
            minWidth: 991
        });

        $('.js-search-button').click(function () {
            $('body').addClass('search-active');
            //$('.input-search').focus();
        });

        $('.js-icon-close').click(function () {
            $('body').removeClass('search-active');
        });

        if ($('.js-modal-instagram').length) {
            $(".js-modal-instagram").iziModal({
                history: false,
                iframe: false,
                fullscreen: false,
                headerColor: '#1A1A1A',
                group: 'instagram',
                loop: true,
                width: 640,
                borderBottom: false,
                radius: 0,
                arrowKeys: false,
                bodyOverflow: true,
                overlayColor: 'rgba(0,0,0,0.8)',
                navigateCaption: false,
            });
        }

        var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        if (!isMobile) {
            $('.jarallax').jarallax();
        }

        objectFitImages();


        $('.js-programs-slider .program-card').matchHeight();
        $('.js-programs-slider').slick({
            vertical: true,
            verticalSwiping: true,
            dots: false,
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            prevArrow: $('.js-slide-down'),
            nextArrow: $('.js-slide-up'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        rows: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 496,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        rows: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });

        if ($('.js-video-modal').length) {
            $(".js-video-modal").iziModal({
                history: false,
                iframe: true,
                fullscreen: true,
                headerColor: '#1A1A1A',
                loop: true,
                group: 'yt-video',
                width: 640,
                borderBottom: false,
                radius: 0,
                arrowKeys: false,
                bodyOverflow: true,
                overlayColor: 'rgba(0,0,0,0.8)',
                navigateCaption: false,
            });
        }



        var fixed = document.querySelector('.fixed-header');
        const mobilMenu = document.querySelector("#menu");
        slideout.on('translate', function (translated) {
            fixed.style.transform = 'translateX(' + translated + 'px)';
        });

        slideout.on('beforeopen', function () {
            fixed.style.transition = 'transform 300ms ease';
            fixed.style.transform = 'translateX(-256px)';
            this.panel.classList.add('panel-open');
            fixed.classList.add('panel-open');
            $('.mobil-prices').slideToggle('300');
        });

        slideout.on('beforeclose', function () {
            fixed.style.transition = 'transform 300ms ease';
            fixed.style.transform = 'translateX(0px)';
            this.panel.classList.remove('panel-open');
            fixed.classList.remove('panel-open');
            $('.mobil-prices').slideToggle('300');
            this.panel.removeEventListener('click', close);
            fixed.removeEventListener('click', close);
        });

        slideout.on('open', function () {
            bodyScrollLock.disableBodyScroll(mobilMenu);
            fixed.style.transition = '';
            this.panel.addEventListener('click', close);
            fixed.addEventListener('click', close);

        });

        slideout.on('close', function () {
            bodyScrollLock.enableBodyScroll(mobilMenu);
            fixed.style.transition = '';
        });

        $('.js-hamburger').click(function (event) {
            slideout.toggle();
        });

        var isButtonClick = false;
        $('.js-newsletter-form').submit(function (e) {
            e.preventDefault();

            if (isButtonClick) {
                return false;
            }

            isButtonClick = true;

            var $this = $(this);

            $.ajax({
                type: "post",
                context: this,
                dataType: "json",
                url: localize.ajaxurl,
                data: {
                    action: "mailchimpsubscribe",
                    datas: $this.serializeArray(),
                },
                success: function (response) {
                    isButtonClick = false;

                    var bottom = null;
                    var width = 600;

                    if (window.innerWidth <= 767) {
                        bottom = 0;
                    }

                    if (!response['fault']) {
                        $this.trigger('reset');
                        if ($('#succes-modal').length) {
                            $('#succes-modal').iziModal('destroy');
                        }
                        $("#succes-modal").iziModal({
                            title: response['msg'],
                            headerColor: '#aacb56',
                            width: width,
                            timeout: 3000,
                            timeoutProgressbar: true,
                            transitionIn: 'fadeInUp',
                            transitionOut: 'fadeOutDown',
                            pauseOnHover: false,
                            radius: 0,
                            bottom: bottom
                        });
                        $('#succes-modal').iziModal('open');

                    } else {
                        if ($('#error-modal').length) {
                            $('#error-modal').iziModal('destroy');
                        }
                        $("#error-modal").iziModal({
                            title: response['msg'],
                            headerColor: '#eb3324',
                            width: width,
                            timeout: 3000,
                            timeoutProgressbar: true,
                            transitionIn: 'fadeInUp',
                            transitionOut: 'fadeOutDown',
                            pauseOnHover: false,
                            radius: 0,
                            bottom: bottom
                        });
                        $('#error-modal').iziModal('open');
                    }
                }
            });

            return false;
        });

        if ($('.js-place-gallery').length) {
            baguetteBox.run('.js-place-gallery', {
                loop: true,
                animation: 'fadeIn',
            });
        }


    });
})(jQuery);