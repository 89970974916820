function validateEmail(email) {
    var re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(String(email).toLowerCase());
}

function validateJsFormRequire(jqueryForm, shouldShow, limitToFields = []) {
    var hasError = false;
    jqueryForm.parents('form').find('[required],.show-required').each(function (el) {
        if (!jQuery(this).val() ||
            jQuery(this).attr('type') === 'email' && !validateEmail(jQuery(this).val()) ||
            jQuery(this).attr('type') === 'checkbox' && jQuery(this).prop('checked') == false) {
            jQuery(this).parents('.form__item').toggleClass('has--error', shouldShow);

            hasError = true;

        }
    });

    return hasError;
}



(function ($) {
    "use strict";
    $(function () {


        if ($('.template-data-requester').length) {
            $.ajax({
                url: localize.ajaxurl,
                type: 'POST',
                data: {
                    action: "get_request_form_limit",
                },
                success: function (data) {
                    var result = JSON.parse(data);
                    $('.js-loader').fadeOut('400', function () {
                        if (result) {
                            $('.js-form').fadeIn();
                        } else {
                            $('.js-expired').fadeIn(function () {

                            });
                        }

                    })


                }
            })
        }

        /* Input placeholder bumping */
        $(document).on('focus js-focus', '.js-input-bump', function (e) {
            var $placeholder = $(this).parent().find('.js-placeholder');
            if ($placeholder.length === 0) {
                $placeholder = $(this).parent().parent().find('.js-placeholder');
            }
            $placeholder.removeClass('return').addClass('expand');
        });

        $(document).on('blur', '.js-input-bump', function (e) {
            if ($(this).val().length === 0) {
                var $placeholder = $(this).parent().find('.js-placeholder');
                if ($placeholder.length === 0) {
                    $placeholder = $(this).parent().parent().find('.js-placeholder');
                }
                $placeholder.removeClass('expand').addClass('return');
            }
        });


        /* Clear value of input */
        $(document).on('input propertychange', '.js-has-clear input', function () {
            var $this = $(this);
            var visible = Boolean($this.val());

            $this.siblings('.js-clear-input').toggleClass('active', visible);
            if ($this.val().length === 0) {
                $this.removeClass('active');
            } else {
                $this.addClass('active');
                $this.parent().removeClass('has--error');
            }
        }).trigger('propertychange');

        /* Form validation */

        var lineEq = (y2, y1, x2, x1, currentVal) => {
            // y = mx + b 
            var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
            return m * currentVal + b;
        };

        var distanceThreshold = { min: 0, max: 75 };
        var opacityInterval = { from: 0, to: 1 };


        $('.js-form-submit').each(function () {
            var $that = $(this);

            new Nearby($that[0], {
                onProgress: (distance) => {
                    const o = lineEq(opacityInterval.from, opacityInterval.to, distanceThreshold.max, distanceThreshold.min, distance);
                    validateJsFormRequire($that, o == 1);
                }
            });
        });


        var hasLetterRegex = /[a-zA-Z]/;
        function removeLetters(string) {
            var r = new RegExp(hasLetterRegex, 'g');
            return string.replace(r, '');
        }
        function formatPhoneNumber(string) {
            return '+' + removeLetters(string).replace('+', '').replace(' ', '');
        }

        function formatNumbers(string) {
            return removeLetters(string).replace('+', '').replace(' ', '');
        }
        var phoneInput = $("input[type=phone]");
        phoneInput.on("keypress keyup blur", function () {
            this.value = formatPhoneNumber(this.value);
        });

        $('.js-numbers-only').on("keypress keyup blur", function () {
            this.value = formatNumbers(this.value);
        });

        if ($('#need_magazin').length) {
            $('#need_magazin').change(function () {
                if (this.checked) {
                    $('.js-magazin-fields').slideDown();
                } else {
                    $('.js-magazin-fields').slideUp();
                }
            });
        }


        $(".js-data-requester-form").on("submit", function (e) {
            e.preventDefault();
            var $this = $(this);

            if (validateJsFormRequire($('.js-data-requester-form').find('.js-form-submit'), true)) {
                return false;
            }

            $this.find(".js-form-submit").prop("disabled", true);

            var formdata = $this.serializeArray();

            $.ajax({
                url: localize.ajaxurl,
                type: 'POST',
                data: {
                    action: "request_form",
                    datas: formdata
                },
                success: function (data) {
                    var result = JSON.parse(data);

                    if (result['status'] == 'error') {
                        if ($('#info-modal').length) {
                            $('#info-modal').iziModal('destroy');
                        }

                        if (result["field"] != "" && result["field"] != "expired") {
                            $(`input[name=${result["field"]}]`).parent().addClass('has--error');
                        }

                        $("#info-modal").iziModal({
                            title: result["title"],
                            subtitle: result["message"],
                            headerColor: '#CF1B1B',
                            width: 600,
                            timeout: 5000,
                            timeoutProgressbar: true,
                            transitionIn: 'fadeInDown',
                            transitionOut: 'fadeOutDown',
                            pauseOnHover: true,
                            autoOpen: true,
                            radius: 0,
                        });

                        if (result["field"] == "expired") {
                            $this.parent().fadeOut(function (e) {
                                $('.js-expired').fadeIn();
                            })
                            $this.trigger('reset');

                        }
                    }

                    if (result['status'] == 'success') {

                        if (result['need_magazin']) {
                            $this.parent().fadeOut(function (e) {
                                $('.js-submit-success').fadeIn();
                            })
                        }

                        if (result['newsletter_ok'] != 'no') {
                            var bottom = null;
                            var width = 600;

                            if (window.innerWidth <= 767) {
                                bottom = 0;
                            }

                            if (result['newsletter_ok']) {
                                if ($('#succes-modal').length) {
                                    $('#succes-modal').iziModal('destroy');
                                }
                                $("#succes-modal").iziModal({
                                    title: result['title'],
                                    headerColor: '#aacb56',
                                    width: width,
                                    timeout: 3000,
                                    timeoutProgressbar: true,
                                    transitionIn: 'fadeInUp',
                                    transitionOut: 'fadeOutDown',
                                    pauseOnHover: false,
                                    radius: 0,
                                    bottom: bottom
                                });
                                $('#succes-modal').iziModal('open');

                            } else {
                                if ($('#error-modal').length) {
                                    $('#error-modal').iziModal('destroy');
                                }
                                $("#error-modal").iziModal({
                                    title: result['title'],
                                    headerColor: '#eb3324',
                                    width: width,
                                    timeout: 3000,
                                    timeoutProgressbar: true,
                                    transitionIn: 'fadeInUp',
                                    transitionOut: 'fadeOutDown',
                                    pauseOnHover: false,
                                    radius: 0,
                                    bottom: bottom
                                });
                                $('#error-modal').iziModal('open');
                            }
                        }
                        $this.trigger('reset');
                        $this.find('.expand').removeClass('expand');
                    }

                }
            }).done(function (response) {
                $this.find(".js-form-submit").prop("disabled", false);
            });
        });


    });
})(jQuery);  