(function ($) {
    "use strict";
    $(function () {

        if ($('.youtube-player-wrapper').length) {
            $('.youtube-player').on('click', function () {

                var $that = $(this);

                var $attr = $that.parent().attr('data-fancybox');

                if (typeof $attr === 'undefined' || $attr == true) {
                    $(this).children().html(`<iframe src="https://www.youtube-nocookie.com/embed/${$(this).data('id')}?autoplay=1&rel=0&fs=0&showinfo=0&enablejsapi=1&modestbranding=1&controls=0&disablekb=1&origin=${document.location.origin}" frameborder="0" allow="autoplay" allowfullscreen="1"></iframe>`);
                }


            });


            var domInfo = {
                usingMobileBrowser: (navigator.userAgent.match(/(Android|iPod|iPhone|iPad|BlackBerry|IEMobile|Opera Mini)/)) ? true : false,
                scrollTop: 0,
                clientX: 0,
                clientY: 0,
                scrollPosMouse: function () {
                    return window.scrollY || $window.scrollTop();
                },
                scrollPosRAF: function () {

                    domInfo.scrollTop = window.scrollY || $window.scrollTop();
                    requestAnimationFrame(domInfo.scrollPosRAF);
                },
                bindEvents: function () {

                    if (!domInfo.usingMobileBrowser) {

                        $window.on('scroll', function () {
                            domInfo.scrollTop = domInfo.scrollPosMouse();
                        });
                        document.addEventListener("mousemove", function (e) {
                            domInfo.clientX = e.clientX;
                            domInfo.clientY = e.clientY;
                        });

                    } else {
                        requestAnimationFrame(domInfo.scrollPosRAF);
                    }



                },
                init: function () {
                    this.scrollTop = this.scrollPosMouse();
                    this.bindEvents();
                }
            };
            window.domInfo = domInfo;

            function linearInterpolate(a, b, n) {
                return (1 - n) * a + n * b;
            }

            function mouseFollowIcon(el, iconSelector) {

                this.$el = el;
                this.$iconEl = this.$el.find(iconSelector);

                this.elX = 0;
                this.elY = 0;

                this.initialX = this.$el.width() / 2;
                this.initialY = this.$el.height() / 2;

                this.elX = 0;
                this.elY = 0;

                this.lastScroll = 0;
                this.scrollAdjust = 0;

                this.lastX = this.initialX;
                this.lastY = this.initialY;
                this.activeFollow = false;

                this.percentage = 0.13;
                this.percentageTimeout;

                $(window).on('resize', this.resizeEvent.bind(this));
                this.mouseEvents();
                this.rafLoop();

            }

            mouseFollowIcon.prototype.resizeEvent = function () {
                this.initialX = this.$el.width() / 2;
                this.initialY = this.$el.height() / 2;
            };

            mouseFollowIcon.prototype.mouseEvents = function () {

                var that = this;

                that.$el.on('mouseenter', function (e) {

                    that.activeFollow = true;

                    that.elX = e.offsetX;
                    that.elY = e.offsetY;

                    that.lastScroll = 0;

                    clearTimeout(that.percentageTimeout);
                    that.percentageTimeout = setTimeout(function () {
                        that.percentage = 0.2;
                    }, 700);
                });

                that.$el.on('mouseleave', function () {

                    that.activeFollow = false;
                    that.lastScroll = 0;

                    clearTimeout(that.percentageTimeout);
                    that.percentage = 0.13;
                });

                that.$el.on('mousemove', function (e) {
                    that.elX = e.offsetX;
                    that.elY = e.offsetY;
                    that.lastScroll = 0;
                });

                $(window).on('scroll', function () {
                    if (that.activeFollow == true && that.lastScroll == 0) {
                        that.lastScroll = domInfo.scrollTop;
                    }
                });

            };
            mouseFollowIcon.prototype.rafLoop = function () {
                if (this.activeFollow == true) {
                    this.scrollAdjust = (this.lastScroll > 0) ? domInfo.scrollTop - this.lastScroll : 0;
                    this.lastY = linearInterpolate(this.lastY, this.elY + this.scrollAdjust, this.percentage);
                    this.lastX = linearInterpolate(this.lastX, this.elX, this.percentage);
                } else {
                    this.lastY = linearInterpolate(this.lastY, this.initialY, 0.13);
                    this.lastX = linearInterpolate(this.lastX, this.initialX, 0.13);
                }
                this.$iconEl.css({
                    'transform': 'translateX(' + this.lastX + 'px) translateY(' + this.lastY + 'px)'
                });
                requestAnimationFrame(this.rafLoop.bind(this));
            };

            $('.youtube-player-wrapper').each(function (i) {
                if ($(this).find('.play__mod')) {
                    var $that = $(this);
                    if (!domInfo.usingMobileBrowser) {
                        new mouseFollowIcon($that, '.play__mod');
                    } else {
                        $that.find('.play__mod').addClass('mobile');
                    }
                    $that.find('.play__mod').addClass('visible');
                }
            });
        }
    });
})(jQuery);
