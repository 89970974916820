/* dev/assets/js/a-video.js */
(function ($) {
    "use strict";
    $(function () {

        // Init Isotope filter

        function concatValues(obj) {
            var value = '';
            for (var prop in obj) {
                value += obj[prop];
            }
            return value;
        }

        var filters = {};
        var partnerFilters = {};
        var placesFilters = {};

        var $grid = $('.programs-wrapper').isotope({
            itemSelector: '.grid-item',
            layoutMode: 'packery',
        });

        var $partnerGrid = $('.partners-offer').isotope({
            itemSelector: '.partner-grid-item',
            layoutMode: 'packery',
        })

        var $placesGrid = $('.places__row').isotope({
            itemSelector: '.places__grid_item',
            layoutMode: 'packery',
        })

        $partnerGrid.imagesLoaded().progress(function () {
            $partnerGrid.isotope('layout');
            $('.js-filter-partners.active').each(function (i, e) {
                var $buttonGroup = $(this).parents('.filter-group');
                var filterGroup = $buttonGroup.attr('data-filter-group');
                partnerFilters[filterGroup] = $(this).attr('data-filter');
            });

            var filterValue = concatValues(partnerFilters);
            $partnerGrid.isotope({ filter: filterValue });

            $('.loader-wrapper').fadeOut('300', function () {
                $('.partners-offer').addClass('loaded');
            })
        });



        $placesGrid.imagesLoaded().progress(function () {
            $placesGrid.isotope('layout');
            $('.js-filter-places.active').each(function (i, e) {
                var $buttonGroup = $(this).parents('.filter-group');
                var filterGroup = $buttonGroup.attr('data-filter-group');
                placesFilters[filterGroup] = $(this).attr('data-filter');
            });

            var filterValue = concatValues(placesFilters);
            $placesGrid.isotope({ filter: filterValue });

            $('.loader-wrapper').fadeOut('300', function () {
                $('.places__row').addClass('loaded');
            })
        });


        $grid.imagesLoaded(function () {
            $grid.isotope('layout');

            $grid.on('layoutComplete', function () {

                setTimeout(function () {
                    var anchor = window.location.hash;

                    if (anchor) {

                        var id = anchor;
                        setTimeout(function () {

                            if ($(id).length > 0) {
                                $('html, body').animate({ scrollTop: $(id).offset().top }, 400);
                            }
                        }, 0)

                    }
                }, 100);

            });

            /* Activate filtering if page is archive programs */
            if ($('.t-programs-archive').length) {
                $('.js-filter.active').each(function (i, e) {
                    var $buttonGroup = $(this).parents('.filter-group');
                    var filterGroup = $buttonGroup.attr('data-filter-group');
                    filters[filterGroup] = $(this).attr('data-filter');
                });

                $('.js-view-title').text($('.js-filter.view__list.active').data('title'));

                if (window.innerWidth < 768) {
                    filters['views'] = '.grid-view';
                    var $gridView = $('.js-view-grid');
                    $gridView.parent().find('.active').removeClass('active');
                    $gridView.addClass('active');
                    $('.js-view-title').text($gridView.data('title'));
                }

                var filterValue = concatValues(filters);
                $grid.isotope({ filter: filterValue });

                $('.loader-wrapper').fadeOut('300', function () {
                    $('.programs-wrapper').addClass('loaded');
                })
            }
        });



        var wasListView = false;
        var wasMapView = false;

        $(window).resize(function () {
            if (window.innerWidth < 768) {
                if ($('.js-filter.view__list,.js-filter.view__map').hasClass('active')) {

                    if ($('.js-filter.view__list').hasClass('active')) {
                        wasListView = true;
                    }
                    if ($('.js-filter.view__map').hasClass('active')) {
                        wasMapView = true;
                    }
                    filters['views'] = '.grid-view';
                    var $gridView = $('.js-view-grid');
                    $gridView.parent().find('.active').removeClass('active');
                    $gridView.addClass('active');
                    $('.js-view-title').text($gridView.data('title'));
                    var filterValue = concatValues(filters);
                    $grid.isotope({ filter: filterValue });
                }
            } else {
                if (wasListView) {
                    wasListView = false;
                    filters['views'] = '.list-view';
                    var $listView = $('.js-view-list');
                    $listView.parent().find('.active').removeClass('active');
                    $listView.addClass('active');
                    $('.js-view-title').text($listView.data('title'));
                    var filterValue = concatValues(filters);
                    $grid.isotope({ filter: filterValue });
                }

                if (wasMapView) {
                    wasMapView = false;
                    filters['views'] = '.map-view';
                    var $mapView = $('.js-view-map');
                    $mapView.parent().find('.active').removeClass('active');
                    $mapView.addClass('active');
                    $('.js-view-title').text($mapView.data('title'));
                    var filterValue = concatValues(filters);
                    $grid.isotope({ filter: filterValue });
                }
            }
        });

        $('.js-places-filter-select').SumoSelect({
            forceCustomRendering: true,
            floatWidth: 300,
            triggerChangeCombined: true,
            placeholder: 'Összes hely',
            okCancelInMulti: true,
            locale: ['Szűrés', 'Mégse'],
            captionFormat: '{0} kiválasztva',
            captionFormatAllSelected: 'Összes hely',
        });

        // filter items on button click
        $('.js-filter').on('click', function () {
            var $this = $(this);
            // get group key
            var $buttonGroup = $this.parents('.filter-group');
            var filterGroup = $buttonGroup.attr('data-filter-group');

            $(this).parent().find('.active').removeClass('active');
            $(this).addClass('active');
            $('.js-view-title').text($(this).data('title'));
            filters[filterGroup] = $this.attr('data-filter');
            var filterValue = concatValues(filters);
            $.filterMarkers(filters['months'].slice(1));
            $.markerCluster.setIgnoreHidden(true)
            $.markerCluster.repaint()
            $.closeAllInfoWindow();
            $.center_filter_map($.filtermap);
            $grid.isotope({ filter: filterValue });
        });


        $('.js-places-filter-select').on('change', function (e) {
            var currentFilters = $(this).val();


            $(`.js-filter-places`).removeClass('active')
            $.each(currentFilters, function (i, v) {
                $(`.js-filter-places[data-filter="${v}"]`).addClass('active')
            });


            if ($('.js-simple-places.active').length == 0) {
                $('.js-all-places').addClass('active');
                $('.js-all-places-text').text('Összes hely');
            } else {
                $('.js-all-places').removeClass('active');
                $('.js-all-places-text').text('Szűrő törlése');
            }


            if (currentFilters != null) {
                currentFilters = currentFilters.join(', ');
            }

            var filterValue = concatValues(currentFilters);

            $.filterMarkerPlaces(filterValue);
            $.markerClusterPlaces.setIgnoreHidden(true)
            $.markerClusterPlaces.repaint()
            $.closeAllInfoWindowPlaces();
            $.center_filter_map_places($.filtermapplaces);
            $placesGrid.isotope({ filter: filterValue });
        });

        $('.js-filter-places').on('click', function () {
            var $this = $(this);

            if ($this.hasClass('js-all-places')) {
                if ($('.js-all-places').hasClass('active')) {
                    return;
                } else {
                    $('.js-simple-places.active').removeClass('active');
                }
            }

            $(this).toggleClass('active');

            if ($('.js-simple-places.active').length == 0) {
                $('.js-all-places').addClass('active');
                $('.js-all-places-text').text('Összes hely');
            } else {
                $('.js-all-places').removeClass('active');
                $('.js-all-places-text').text('Szűrő törlése');
            }


            var currentFilters = [];


            $('.js-filter-places.active').each(function (i, e) {
                currentFilters.push($(this).attr('data-filter'));
                $('.js-places-filter-select')[0].sumo.selectItem($(this).attr('data-filter'));
            });

            $('.js-filter-places:not(.active)').each(function (i, e) {
                $('.js-places-filter-select')[0].sumo.unSelectItem($(this).attr('data-filter'));
            });

            currentFilters = currentFilters.join(', ');

            var filterValue = concatValues(currentFilters);

            $.filterMarkerPlaces(filterValue);
            $.markerClusterPlaces.setIgnoreHidden(true)
            $.markerClusterPlaces.repaint()
            $.closeAllInfoWindowPlaces();
            $.center_filter_map_places($.filtermapplaces);
            $placesGrid.isotope({ filter: filterValue });

        });



        $('.js-filter-partners').on('click', function () {
            var $this = $(this);
            // get group key
            var $buttonGroup = $this.parents('.filter-group');
            var filterGroup = $buttonGroup.attr('data-filter-group');

            $(this).parent().find('.active').removeClass('active');
            $(this).addClass('active');

            partnerFilters[filterGroup] = $this.attr('data-filter');
            var filterValue = concatValues(partnerFilters);
            $partnerGrid.isotope({ filter: filterValue });
        });




    });
})(jQuery);
