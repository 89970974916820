(function ($) {
    "use strict";
    $(function () {


        moment.locale($('html').attr('lang'));

        $.findObjectByKey = function findObjectByKey(array, key, value) {
            for (var i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i];
                }
            }
            return null;
        }


        if ($('.js-tickets').length || $('.template-single-programs').length) {
            axios.get(localize.ticketsJson + '?v=' + Date.now(), { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' } })
                .then(function (response) {

                    $.tickets = response.data.products;


                    $(document).on('click', '.js-buy-ticket', function () {
                        var sum = $(this).parent().find('.ctrl-counter-input').val();
                        var id = $(this).data('id');
                        var $currentTicket = $.findObjectByKey($.tickets, 'id', id);
                        var max = $currentTicket.cart_limit;
                        if (sum < 1) {
                            sum = 1;
                        }
                        if (sum > max) {
                            sum = max;
                        }
                        window.location.href = localize.basket + $(this).data('id') + '/' + sum;
                    });

             

                    if ($('.template-single-programs').length) {
                        var $program_id = $('.template-single-programs').data('id');
                        var $currentTickets = localize.programs[$program_id];
                        var $allTickets = $currentTickets.tickets;

                        /* Sticky ticket */
                        var $sticky_sold = true;
                        var maxTickets = 2;
                        $.each($allTickets, function (i, val) {
                            var $currentTicket = $.findObjectByKey($.tickets, 'code', val.toString());
                            if (maxTickets <= 0) {
                                return;
                            }
                            if ($currentTicket.selling_status == 'available') {
                                $sticky_sold = false;
                                var $ticketCounter = 0;
                                $.each($currentTicket.price_phases, function (i, val) {

                                    if (val.price_status == "active") {
                                        if($('.js-sticky-prices .coming-soon-ticket').length){
                                            $('.js-sticky-prices').html('');
                                        }

                                        $('.js-sticky-prices').append(`
                                        <h5 class="tickets-price ticket__preferential">
                                        ${localize.translations.tickets} ${val.gross_price} ${$currentTicket.currency_sign}${localize.translations.from}
                                        </h5>
                                        `);

                                        $('.js-tickets-button a').attr('href',localize.basket+$currentTicket.id);
                                        $('.js-tickets-button').show();
                                        $ticketCounter++;
                                        maxTickets--;
                                    }


                                });

                                if ($ticketCounter >= 2) {
                                    return false;
                                }
                            }

                            if ($currentTicket.selling_status == 'coming_soon' && !$('.js-sticky-prices .ticket__preferential').length) {
                                $sticky_sold = false;
                        
                                $('.js-sticky-prices').append(`
                                <h6 class="tickets-price ticket__preferential coming-soon-ticket" data-date="${moment($currentTicket.selling_from.toString()).format('YYYY-MM-DD HH:mm')}">
                                ${localize.translations.selling_from}<br>${moment($currentTicket.selling_from.toString()).format('YYYY. MM. DD. HH:mm').toLowerCase()}
                                </h6>
                              
                                `);
                                var mincoming = moment($currentTicket.selling_from.toString()).format('YYYY-MM-DD HH:mm');
                                var minindex = 0;

                                $('.js-sticky-prices').find('.coming-soon-ticket').each(function (i, e) {
                                    $(this).hide();
                                    var cmin = moment($(this).data('date')).format('YYYY-MM-DD HH:mm');

                                    if (cmin <= mincoming) {
                                        mincoming = cmin;
                                        minindex = i;
                                    }
                                });

                                var toshowcoming = $('.js-sticky-prices').find('.coming-soon-ticket').eq(minindex).data('date');
                                $(`.js-sticky-prices .coming-soon-ticket[data-date="${toshowcoming}"]`).show();
                            }




                        })
                        if ($sticky_sold) {
                           
                            $('.js-sticky-prices').html(`
                            <h5 class="tickets-price ticket__preferential">
                            ${localize.translations.all_tickets_sold}
                            </h5>
                          
                            `);
                        }

                        $
                    }

                    if ($('.js-tickets').length) {

                        $('.js-tickets').each(function (index, el) {
                            var $program_id = $(this).data('id');
                            var $currentTickets = localize.programs[$program_id];
                            var $that = $(this);
                            var $tickets_sold = true;
                            var $notickets = false;
                            var $isfree = $currentTickets.isfree;
                            var $isexternal = $currentTickets.external;
                            var $issoldout = $currentTickets.soldout;

                            if ($currentTickets.tickets != undefined) {
                                var $allTickets = $currentTickets.tickets;
                                var maxTickets = 2;
                                $.each($allTickets, function (i, val) {
                                    var $currentTicket = $.findObjectByKey($.tickets, 'code', val.toString());
                                    if (maxTickets <= 0) {
                                        return;
                                    }
                                    if ($currentTicket) {
                                        $notickets = false;
                                        if ($currentTicket.selling_status == "available") {

                                            $tickets_sold = false;
                                            var $ticketCounter = 0;
                                            $.each($currentTicket.price_phases, function (i, val) {

                                                if ($ticketCounter < 2) {
                                                    if (val.price_status == "active") {

                                                        if($that.find('.coming-soon-ticket').length){
                                                            $that.html('');
                                                        }

                                                        $that.append(`<div class="available-ticket"><h5>${localize.translations.tickets}</h5> <h4>${val.gross_price} ${$currentTicket.currency_sign}${localize.translations.from}</h4></div>`);
                                                        $ticketCounter++;
                                                        maxTickets--;
                                                    }
                                                }
                                            });

                                            if ($ticketCounter >= 2) {
                                                return false;
                                            }
                                        }

                                        if ($currentTicket.selling_status == 'coming_soon' && !$that.find('.available-ticket').length) {
                                            $tickets_sold = false;
                                            $that.append(`<div class="price--large coming--soon strong coming-soon-ticket" data-date="${moment($currentTicket.selling_from.toString()).format('YYYY-MM-DD HH:mm')}"><h5>${localize.translations.selling_from}</h5><h4>${moment($currentTicket.selling_from.toString()).format('YYYY. MM. DD. HH:mm').toLowerCase()}</h4></div>`);
                                         
                                            var mincoming = moment($currentTicket.selling_from.toString()).format('YYYY-MM-DD HH:mm');
                                            var minindex = 0;

                                            $that.find('.coming-soon-ticket').each(function (i, e) {
                                                $(this).hide();
                                                var cmin = moment($(this).data('date')).format('YYYY-MM-DD HH:mm');

                                                if (cmin <= mincoming) {
                                                    mincoming = cmin;
                                                    minindex = i;

                                                }
                                            });

                                            $that.find('.coming-soon-ticket').eq(minindex).show();
                                        }
                                    } else {
                                        $notickets = true;
                                    }
                                });
                            }

                            if ($currentTickets.tickets == undefined || $currentTickets.tickets.length == 0 || $notickets) {
                                if ($isexternal && !$issoldout) {

                                } else {
                                    if ($isfree) {
                                        $that.append(`<p class="price--large strong">${localize.translations.free}</p>`);
                                     
                                    }

                                    else if ($issoldout) {
                                        $that.append(`<h4>${localize.translations.all_tickets_sold}</h4>`);
                                    }
                                }
                                return;
                            }

                            if ($tickets_sold && !$notickets) {
                                $that.append(`<h4>${localize.translations.all_tickets_sold}</h4>`);
                            }

                        });
                    }
            
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });

        }


    });
})(jQuery);