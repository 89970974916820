(function($) {
    $(function() {

        /*Cookie Policy*/
        function cookiesPolicyBar() {
            // Check cookie
            if (Cookies.get('cookiepolicy') != "active") $('#cookieAcceptBar').show();
            //Assign cookie on click
            $('#cookieAcceptBarConfirm').on('click', function() {
                Cookies.set('cookiepolicy', 'active', { expires: 31, path: '/' });
                $('#cookieAcceptBar').fadeOut();
            });
        }

        cookiesPolicyBar();

    });
})(jQuery);