(function ($) {


    function new_filter_map_places($el) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
                {
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#F1F1F1"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "color": "#1A1A1A"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#e53329"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        };


        // create map	        	
        var map = new google.maps.Map($el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function () {

            add_filter_places_marker($(this), map);

        });

        var clusterStyles = [
            {
                textColor: 'white',
                url: localize.themeurl + '/assets/img/svg/pinek/cluster.svg?v=1',
                height: 30,
                width: 30
            },
        ];

        //markerClusterPlaceser
        var options = {
            styles: clusterStyles,
        };

        $.markerClusterPlaces = new MarkerClusterer(map, (map.markers).filter(e => e.category != 'gas_station'), options);

        // center map
        $.center_filter_map_places(map);

        //Close infowindows when clicking away
        google.maps.event.addListener(map, "click", function (event) {
            $.closeAllInfoWindowPlaces();
        });

        // return
        return map;

    }

    /*
     *  add_filter_places_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    function add_filter_places_marker($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));


        var icon = {
            url: $marker.attr('data-icon'),
            scaledSize: new google.maps.Size(50, 50)
        };

        var iconDefafult = {
            url: $marker.attr('data-icon'),
            scaledSize: new google.maps.Size(50, 50)
        };

        var iconActive = {
            url: $marker.attr('data-iconactive'),
            scaledSize: new google.maps.Size(50, 50)
        };

        if ($marker.hasClass('gas__station_marker')) {
            icon = {
                url: localize.themeurl + '/assets/img/svg/marker/mol_logo.svg?v=1234',
                scaledSize: new google.maps.Size(33, 44)
            };
            iconDefafult = {
                url: localize.themeurl + '/assets/img/svg/marker/mol_logo.svg?v=1234',
                scaledSize: new google.maps.Size(33, 44)
            };
            iconActive = {
                url: localize.themeurl + '/assets/img/svg/marker/mol_logo.svg?v=1234',
                scaledSize: new google.maps.Size(33, 44)
            };
        }

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: icon,
            iconActive: iconActive,
            iconDefafult: iconDefafult,
            category: $marker.data('category'),
        });

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window

            marker['infowindow'] = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {
                if (currentmarkerplaces != this) {
                    marker.setIcon(marker.iconActive);

                    $.closeAllInfoWindowPlaces();


                    currentinfowindowplaces = this['infowindow'];
                    currentmarkerplaces = this;
                    currentinfowindowplaces.open(map, this);
                    //map.setCenter(this.getPosition());
                    removeDefaultInfowindow();
                }
            });
        }

    }

    $.closeAllInfoWindowPlaces = function closeAllInfoWindowPlaces() {
        if (currentinfowindowplaces) {
            currentinfowindowplaces.close();
            currentinfowindowplaces = null;
        }
        if (currentmarkerplaces) {

            currentmarkerplaces.setIcon(currentmarkerplaces.iconDefafult);
            currentmarkerplaces = null;
        }


    }

    $(document).on('click', '.js-close-infowindow', function (e) {
        e.preventDefault();
        $.closeAllInfoWindowPlaces();
    });

    /*
     *  center_filter_map_places
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    $.center_filter_map_places = function center_filter_map_places(map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(14);
        } else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    }


    //Removing default infoWindow styles
    function removeDefaultInfowindow() {
        $('.gm-style-iw').next().css({ 'height': '0px' }); //remove arrow bottom inforwindow
        $('.gm-style-iw').prev().html(''); //remove style default inforwindows
        $('.gm-style-iw').parent().css('pointer-events', 'none');
    }


    //Filtering markers
    $.filterMarkerPlaces = function filterMarkerPlaces(category) {

        var categories = category.split(', ');
        var filterAbleMarkers = $.filtermapplaces.markers.filter(e => e.category != 'gas_station')


        for (var i = 0; i < filterAbleMarkers.length; i++) {
            var markertohide = filterAbleMarkers[i];
            var markertohidecategories = markertohide.category.split(' ');
            /*  console.log(markertohidecategories); */

            if (markertohidecategories.some(r => categories.indexOf('.' + r) >= 0) || category.length === 0) {
                markertohide.setVisible(true);
            }
            // Categories don't match
            else {
                markertohide.setVisible(false);
            }
        }

    }

    /*
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	5.0.0
     *
     *  @param	n/a
     *  @return	n/a
     */
    // global var
    $.filtermapplaces = null;
    var currentinfowindowplaces = null;
    var currentmarkerplaces = null;
    $.markerClusterPlaces;
    $(function () {

        $('.js-filter-map-places').each(function () {

            // create map
            $.filtermapplaces = new_filter_map_places($(this));

        });

    });

})(jQuery);